import React from "react";
import Footer from "src/components/Footer_gr";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/el_GR/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/gr/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/gr/our-products/" target="_self">
                      ΤΑ ΠΡΟΪOΝΤΑ ΜΑΣ
                    </a>
                    <ul>
                      <li>
                        <a href="/gr/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/sensitive/">Sensitive</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>                      
                      <li>
                        <a href="/gr/our-products/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/our-heritage/" target="_self">
                      Η ΜAΡΚΑ PIZ BUIN®
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/know_the_sun/" target="_self">
                      ΓΝΩΡΙΣΤΕ ΤΟΝ ΗΛΙΟ
                    </a>
                  </li>
               {/*<li>
                    <a className="es_header" href="/gr/Where-Have-You-Been/" target="_self">
                      Where Have You Been 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      ΧΩΡΕΣ
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2019-banner-moisturising.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/2019-moisturising-mobile.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> Moisturising
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Ενυδατικά αντηλιακά</h3>
                      <p>
                        Με προηγμένα φίλτρα UVA / UVB, αυτά τα αντηλιακά σπρέι
                        παρέχουν άμεση και αποτελεσματική προστασία από τον
                        ήλιο. Εμπλουτισμένα με την ισχυρή αντιοξειδωτική δράση*
                        FEVERFEWPFE ™, συμβάλλουν στην πρόληψη της πρόωρης
                        γήρανσης που προκαλεί ο ήλιος. Η εξαιρετικά ελαφριά, μη
                        λιπαρή σύνθεσή τους που δεν κολλάει απορροφάται αμέσως
                        από την επιδερμίδα σας χωρίς να αφήνει λευκά σημάδια.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="moisturising-sun-lotion" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-moisturising-sun-lotion-50spf-200ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>MOISTURISING SUN LOTION</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>ΟΦΕΛΗ</h4>
                        <p>
                          Η μη-λιπαρή, μη-κολλώδης σύνθεση των PIZ BUIN
                          <sup>®</sup> MOISTURISING Sun Lotions ενυδατώνει
                          εντατικά την επιδερμίδα σας ενώ μαυρίζετε, αφήνοντάς
                          την απαλή και μεταξένια όλη την ημέρα. Οι PIZ BUIN
                          <sup>®</sup> MOISTURISING Sun Lotions είναι ανθεκτικές
                          στο νερό και τον ιδρώτα.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>ΔΙΑΘΕΣΙΜΟ ΣΕ SPF</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>ΟΔΗΓΙΕΣ ΧΡΗΣΗΣ</h4>
                        <p>
                          Απλώστε επαρκή ποσότητα ομοιόμορφα πριν την έκθεση
                          στον ήλιο. Η μείωση της ενδεδειγμένης ποσότητας
                          περιορίζει σημαντικά το επίπεδο προστασίας.
                          Επαναλάβετε συχνά την εφαρμογή, ιδιαίτερα μετά από
                          εφίδρωση, κολύμπι ή σκούπισμα με πετσέτα. Αποφύγετε
                          τον μεσημεριανό ήλιο και την παρατεταμένη έκθεση στον
                          ήλιο ακόμα και με τη χρήση αντηλιακού. Κρατήστε τα
                          βρέφη και τα παιδιά μακριά από την απευθείας έκθεση
                          στην άμεση ακτινοβολία του ήλιου.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              ΣΥΣΤΑΤΙΚΑ &amp; ΤΕΧΝΟΛΟΓΙΕΣ{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                Προηγμένο αντηλιακό σύστημα φίλτρων κατά των
                                ακτίνων UVA/UVB PIZ BUIN®
                                <div className="textBlock bottom">
                                  <p>
                                    Ως ειδικοί στην αντηλιακή φροντίδα,
                                    χρησιμοποιούμε καινοτόμες τεχνολογίες για να
                                    εξασφαλίσουμε ότι επιτυγχάνεται η σωστή
                                    ισορροπία μαυρίσματος και προστασίας όταν
                                    απολαμβάνετε τον ήλιο.
                                  </p>
                                  <p>
                                    Κάθε προηγμένο αντηλιακό σύστημα φίλτρων
                                    κατά των ακτίνων UVA/UVB PIZ BUIN
                                    <sup>®</sup> είναι μία ισχυρή τεχνολογία
                                    προστασίας από τον ήλιο που έχει αναπτυχθεί
                                    από την Kenvue. Προσφέρουν
                                    αποτελεσματική, φωτοσταθερή και ευρέως
                                    φάσματος προστασία κατά των ακτίνων UVA και
                                    UVB, που δηλώνει ότι τα αντηλιακά PIZ BUIN
                                    <sup>®</sup> είναι σύμφωνα με τους ισχύοντες
                                    ευρωπαϊκούς κανονισμούς.
                                  </p>
                                  <p>
                                    Όλα τα προηγμένα αντηλιακά συστήματα φίλτρων
                                    κατά των ακτίνων UVA/UVB PIZ BUIN®
                                    συμβάλλουν στην προστασία από την υπεριώδη
                                    ακτινοβολία UVA και UVB.
                                  </p>
                                  <p>
                                    Οι ακτίνες UVB - «οι ακτίνες που προκαλούν
                                    έγκαυμα» - βλάπτουν την επιδερμίδα και είναι
                                    η κύρια αιτία του ηλιακού εγκαύματος.
                                  </p>
                                  <p>
                                    Οι ακτίνες UVA – «ακτίνες γήρανσης» -
                                    εισχωρούν βαθύτερα και καταστρέφουν το
                                    κολλαγόνο και την ελαστίνη, το οποίο μπορεί
                                    να οδηγήσει σε πρόωρη γήρανση του δέρματος
                                    και καρκίνο.
                                  </p>
                                  <p>
                                    Τα προηγμένα αντηλιακά συστήματα φίλτρων
                                    κατά των ακτίνων UVA/UVB PIZ BUIN® περιέχουν
                                    φίλτρα και για τους δύο τύπους ακτίνων (UVA
                                    και UVB) για την προστασία ενάντια στον ήλιο
                                    και τη φθορά του δέρματος μακροπρόθεσμα, ενώ
                                    παράλληλα προστατεύουν από τις πλέον σοβαρές
                                    επιπτώσεις της υπεριώδους ακτινοβολίας UVA.
                                    Καλύπτουν το μεγαλύτερο μέρος του φάσματος
                                    της υπεριώδους ακτινοβολίας UVA / UVB,
                                    προσφέροντας ευρεία προστασία στο δέρμα σας.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Καθαρό εκχύλισμα χρυσάνθεμου (Feverfew PFE™)
                                <div className="textBlock bottom">
                                  <p>
                                    Το χρυσάνθεμο φυτρώνει στις ορεινές λόχμες
                                    και τα βραχώδη εδάφη της βαλκανικής
                                    χερσονήσου. Η μητρική εταιρεία του PIZ
                                    BUIN®, Kenvue, έχει
                                    κατοχυρώσει με δίπλωμα ευρεσιτεχνίας το
                                    συγκεκριμένο καινοτόμο εκχύλισμα για χρήση
                                    στην περιποίηση του δέρματος, εφαρμόζοντας
                                    μία νέα διαδικασία εκχύλισης που διατηρεί τα
                                    ενεργά συστατικά και εξαλείφει τις
                                    ανεπιθύμητες ουσίες που θα μπορούσαν να
                                    προκαλέσουν αλλεργίες.
                                  </p>
                                  <p>
                                    Πώς το καθαρό εκχύλισμα χρυσάνθεμου
                                    (Feverfew PFE™) βοηθά την επιδερμίδα σας;
                                  </p>
                                  <p>
                                    Το φυτικό καθαρό εκχύλισμα χρυσάνθεμου
                                    (Feverfew PFE™) είναι ένα ιδιαίτερα
                                    αποτελεσματικό καταπραϋντικό και
                                    αντιοξειδωτικό συστατικό. Μελέτες in vitro
                                    αποδεικνύουν ότι έχει πιο αυξημένη
                                    αντιοξειδωτική δράση σε σχέση με άλλα βασικά
                                    εκχυλίσματα όπως το φασκόμηλο, το πράσινο
                                    και το μαύρο τσάι, η βιταμίνη C και η
                                    βιταμίνη Ε. Συμβάλλει στην ανακούφιση της
                                    ερυθρότητας του δέρματος, στην ανάπλαση του
                                    κατεστραμμένου κυτταρικού DNA του δέρματος
                                    από τις υπεριώδεις ακτινοβολίες, καθώς και
                                    στην ενίσχυση της ανθεκτικότητας του
                                    δέρματος στον ήλιο*. Η αποτελεσματικότητά
                                    του έχει αποδειχθεί μετά από πολλά χρόνια
                                    εκτεταμένων ερευνών που έχουν διεξαχθεί από
                                    τη μητρική εταιρεία του PIZ BUIN®, Johnson
                                    &amp; Johnson, με αρκετά διπλώματα
                                    ευρεσιτεχνίας, τα οποία καλύπτουν την
                                    εφαρμογή του στον τομέα της περιποίησης του
                                    δέρματος και της κοσμετολογίας.
                                  </p>
                                  <p className="footNote">
                                    Πηγή: Derm Conversation Feb 2004 *
                                    Δοκιμασμένο in vitro
                                  </p>
                                  <p>Εικ. 1</p>
                                  <p>
                                    Το καθαρό εκχύλισμα χρυσάνθεμου (Feverfew
                                    PFE™) συμβάλλει στην προστασία κατά των
                                    αλλοιώσεων στα κύτταρα, στη μείωση του
                                    ερυθήματος που προκαλείται από την
                                    ακτινοβολία UVB, καθώς και στην ανάπλαση του
                                    κατεστραμμένου κυτταρικού DNA του δέρματος
                                    από τις υπεριώδεις ακτινοβολίες.
                                  </p>
                                  <p>
                                    Έως και 60% βελτίωση στο ερύθημα και την
                                    ερυθρότητα του δέρματος που προκαλείται από
                                    την ακτινοβολία UVB.
                                  </p>
                                  <p>
                                    Πηγή: Kenvue publication in
                                    Arch Dermatol Res Feb 2008; 300 (2) 69-80,
                                    τυχαιοποιημένη, ελεγχόμενη από εικονικό
                                    φάρμακο, διπλά τυφλή μελέτη σε 12 εθελοντές.
                                  </p>
                                  <p>Εικ. 2</p>
                                  <p>
                                    Το καθαρό εκχύλισμα χρυσάνθεμου (Feverfew
                                    PFE™) συμβάλλει στην προστασία κατά του
                                    ηλιακού εγκαύματος διεγείροντας τις φυσικές
                                    διαδικασίες που βοηθούν στη διατήρηση της
                                    κυτταρικής συνοχής* του δέρματος,
                                    ενισχύοντας παράλληλα την ανθεκτικότητα του
                                    δέρματος στον ήλιο.
                                  </p>
                                  <p>
                                    Πηγή: Kenvue R&amp;D,
                                    Μέθοδος: εφαρμογή ακτινοβολιών UV σε
                                    ανθρώπινο δερματικό μόσχευμα και αξιολόγηση
                                    της αλλοίωσης των κυττάρων από το ηλιακό
                                    έγκαυμα.
                                  </p>
                                  <p className="footNote">
                                    *Δοκιμασμένο in vitro
                                  </p>
                                  <p>
                                    To Feverfew PFE περιέχεται στα προϊόντα PIZ
                                    BUIN® In Sun Moisturising Sun Lotion και PIZ
                                    BUIN® In Sun Ultra Light Sun Spray.*
                                  </p>
                                  <p className="footNote">
                                    *Με εξαίρεση το PIZ BUIN® In Sun Ultra Light
                                    Sun Spray SPF 10
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/39ef095da165482afdc673791b8d83e8_f42.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Γλυκερίνη
                                <div className="textBlock bottom">
                                  <p>
                                    Η γλυκερίνη προσελκύει το νερό και το
                                    συγκρατεί στο δέρμα, το οποίο βοηθά στη
                                    διατήρηση ενός καλού επιπέδου ενυδάτωσης.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="stick-labial" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-moisturising-sun-lipstick-aloe-vera.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>
                        Piz Buin® Moisturising Sun Lipstick Extra Care Αντηλιακη
                        Stick Χειλιων με Αλoε Βερα SPF30
                      </h3>
                      <div className="products-page__product__details__benefits">
                        <h4>ΟΦΕΛΗ</h4>
                        <p>
                          PIZ BUIN<sup>®</sup> Moisturising Sun Lipstick Extra
                          Care.
                        </p>
                        <p>
                          Εντατική ενυδάτωση και υψηλή προστασία για τα χείλη
                          σας
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>ΔΙΑΘΕΣΙΜΟ ΣΕ SPF</h4>
                        <span className="factor factor30a" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>ΟΔΗΓΙΕΣ ΧΡΗΣΗΣ</h4>
                        <p>
                          Εφαρμόστε ομοιόμορφα μια γενναιόδωρη ποσότητα πριν την
                          έκθεση στον ήλιο. Μειωμένη ποσότητα μειώνει σημαντικά
                          το επίπεδο προστασίας. Επαναλάβετε συχνά την εφαρμογή,
                          ειδικά μετά από εφίδρωση, κολύμπι και σκούπισμα με
                          πετσέτα. Αποφύγετε το μεσημεριανό ήλιο και την
                          παρατεταμένη παραμονή ακόμα και με τη χρήση
                          αντηλιακού. Κρατήστε βρέφη και παιδιά μακριά από την
                          απευθείας έκθεση στην άμεση ακτινοβολία του ηλίου.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="ultra-light-hydrating-sun-spray" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-moisturising-ultra-light-sun-spray-30spf-200ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>ULTRA LIGHT HYDRATING SUN SPRAY</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>ΟΦΕΛΗ</h4>
                        <p>
                          Με προηγμένα φίλτρα UVA / UVB, αυτά τα αντηλιακά σπρέι
                          παρέχουν άμεση και αποτελεσματική προστασία από τον
                          ήλιο. Εμπλουτισμένα με την ισχυρή αντιοξειδωτική
                          δράση* FEVERFEWPFE <sup>™</sup>, συμβάλλουν στην
                          πρόληψη της πρόωρης γήρανσης που προκαλεί ο ήλιος. Η
                          εξαιρετικά ελαφριά, μη λιπαρή σύνθεσή τους που δεν
                          κολλάει απορροφάται αμέσως από την επιδερμίδα σας
                          χωρίς να αφήνει λευκά σημάδια. Παρέχουν μακράς
                          διάρκειας ενυδάτωση για να διατηρήσετε την επιδερμίδα
                          σας απαλή και προστατευμένη κατά την έκθεση στον ήλιο.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            Τα PIZ BUIN<sup>®</sup> ULTRA LIGHT Hydrating Sun
                            Sprays είναι ανθεκτικά στο νερό και τον ιδρώτα.
                          </p>
                          <p>
                            Τα PIZ BUIN<sup>®</sup> ULTRA LIGHT Hydrating Sun
                            Sprays έχουν την προτίμηση των καταναλωτών. Ζητήσαμε
                            από τους καταναλωτές να μας πουν τη γνώμη τους για
                            τα Νέα PIZ BUIN<sup>®</sup> ULTRA LIGHT Hydrating
                            Sun Spray**.
                          </p>
                          <p>
                            <strong className="orange">Το 100%</strong> των
                            καταναλωτών δήλωσαν:
                          </p>
                          <ul>
                            <li>Άμεση απορρόφηση</li>
                            <li>Ανάλαφρη υφή</li>
                            <li>Μη λιπαρή υφή</li>
                          </ul>
                          <p>
                            <strong className="orange">Το 95%</strong> των
                            καταναλωτών δήλωσαν:
                          </p>
                          <ul>
                            <li>Ενυδάτωση που διαρκεί όλη την ημέρα</li>
                          </ul>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Διαβάστε περισσότερα
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>ΔΙΑΘΕΣΙΜΟ ΣΕ SPF</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>ΟΔΗΓΙΕΣ ΧΡΗΣΗΣ</h4>
                        <p>
                          Απλώστε επαρκή ποσότητα ομοιόμορφα πριν την έκθεση
                          στον ήλιο. Η μείωση της ενδεδειγμένης ποσότητας
                          περιορίζει σημαντικά το επίπεδο προστασίας.
                          Επαναλάβετε συχνά την εφαρμογή, ιδιαίτερα μετά από
                          εφίδρωση, κολύμπι ή σκούπισμα με πετσέτα. Αποφύγετε
                          τον μεσημεριανό ήλιο και την παρατεταμένη έκθεση στον
                          ήλιο ακόμα και με τη χρήση αντηλιακού. Κρατήστε τα
                          βρέφη και τα παιδιά μακριά από την απευθείας έκθεση
                          στην άμεση ακτινοβολία του ήλιου. Μην ψεκάζετε
                          απευθείας στο πρόσωπο. Για χρήση στο πρόσωπο, ψεκάστε
                          στα χέρια σας και απλώστε αποφεύγοντας την επαφή με τα
                          μάτια.
                        </p>
                        <p className="footNote">
                          * In vitro
                          <br />
                          ** Αποτελέσματα αυτοαξιολόγησης που ελήφθησαν για το
                          SPF 30 Sun Spray, 21 συμμετέχοντες, εφαρμογή
                          τουλάχιστον μία φορά την ημέρα για διάστημα 1
                          εβδομάδας.{" "}
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              ΣΥΣΤΑΤΙΚΑ &amp; ΤΕΧΝΟΛΟΓΙΕΣ{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                Προηγμένο αντηλιακό σύστημα φίλτρων κατά των
                                ακτίνων UVA/UVB PIZ BUIN®
                                <div className="textBlock bottom">
                                  <p>
                                    Ως ειδικοί στην αντηλιακή φροντίδα,
                                    χρησιμοποιούμε καινοτόμες τεχνολογίες για να
                                    εξασφαλίσουμε ότι επιτυγχάνεται η σωστή
                                    ισορροπία μαυρίσματος και προστασίας όταν
                                    απολαμβάνετε τον ήλιο.
                                  </p>
                                  <p>
                                    Κάθε προηγμένο αντηλιακό σύστημα φίλτρων
                                    κατά των ακτίνων UVA/UVB PIZ BUIN
                                    <sup>®</sup> είναι μία ισχυρή τεχνολογία
                                    προστασίας από τον ήλιο που έχει αναπτυχθεί
                                    από την Kenvue. Προσφέρουν
                                    αποτελεσματική, φωτοσταθερή και ευρέως
                                    φάσματος προστασία κατά των ακτίνων UVA και
                                    UVB, που δηλώνει ότι τα αντηλιακά PIZ BUIN
                                    <sup>®</sup> είναι σύμφωνα με τους ισχύοντες
                                    ευρωπαϊκούς κανονισμούς.
                                  </p>
                                  <p>
                                    Όλα τα προηγμένα αντηλιακά συστήματα φίλτρων
                                    κατά των ακτίνων UVA/UVB PIZ BUIN®
                                    συμβάλλουν στην προστασία από την υπεριώδη
                                    ακτινοβολία UVA και UVB.
                                  </p>
                                  <p>
                                    Οι ακτίνες UVB - «οι ακτίνες που προκαλούν
                                    έγκαυμα» - βλάπτουν την επιδερμίδα και είναι
                                    η κύρια αιτία του ηλιακού εγκαύματος.
                                  </p>
                                  <p>
                                    Οι ακτίνες UVA – «ακτίνες γήρανσης» -
                                    εισχωρούν βαθύτερα και καταστρέφουν το
                                    κολλαγόνο και την ελαστίνη, το οποίο μπορεί
                                    να οδηγήσει σε πρόωρη γήρανση του δέρματος
                                    και καρκίνο.
                                  </p>
                                  <p>
                                    Τα προηγμένα αντηλιακά συστήματα φίλτρων
                                    κατά των ακτίνων UVA/UVB PIZ BUIN® περιέχουν
                                    φίλτρα και για τους δύο τύπους ακτίνων (UVA
                                    και UVB) για την προστασία ενάντια στον ήλιο
                                    και τη φθορά του δέρματος μακροπρόθεσμα, ενώ
                                    παράλληλα προστατεύουν από τις πλέον σοβαρές
                                    επιπτώσεις της υπεριώδους ακτινοβολίας UVA.
                                    Καλύπτουν το μεγαλύτερο μέρος του φάσματος
                                    της υπεριώδους ακτινοβολίας UVA / UVB,
                                    προσφέροντας ευρεία προστασία στο δέρμα σας.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Καθαρό εκχύλισμα χρυσάνθεμου (Feverfew PFE™)
                                <div className="textBlock bottom">
                                  <p>
                                    Το χρυσάνθεμο φυτρώνει στις ορεινές λόχμες
                                    και τα βραχώδη εδάφη της βαλκανικής
                                    χερσονήσου. Η μητρική εταιρεία του PIZ
                                    BUIN®, Kenvue, έχει
                                    κατοχυρώσει με δίπλωμα ευρεσιτεχνίας το
                                    συγκεκριμένο καινοτόμο εκχύλισμα για χρήση
                                    στην περιποίηση του δέρματος, εφαρμόζοντας
                                    μία νέα διαδικασία εκχύλισης που διατηρεί τα
                                    ενεργά συστατικά και εξαλείφει τις
                                    ανεπιθύμητες ουσίες που θα μπορούσαν να
                                    προκαλέσουν αλλεργίες.
                                  </p>
                                  <p>
                                    Πώς το καθαρό εκχύλισμα χρυσάνθεμου
                                    (Feverfew PFE™) βοηθά την επιδερμίδα σας;
                                  </p>
                                  <p>
                                    Το φυτικό καθαρό εκχύλισμα χρυσάνθεμου
                                    (Feverfew PFE™) είναι ένα ιδιαίτερα
                                    αποτελεσματικό καταπραϋντικό και
                                    αντιοξειδωτικό συστατικό. Μελέτες in vitro
                                    αποδεικνύουν ότι έχει πιο αυξημένη
                                    αντιοξειδωτική δράση σε σχέση με άλλα βασικά
                                    εκχυλίσματα όπως το φασκόμηλο, το πράσινο
                                    και το μαύρο τσάι, η βιταμίνη C και η
                                    βιταμίνη Ε. Συμβάλλει στην ανακούφιση της
                                    ερυθρότητας του δέρματος, στην ανάπλαση του
                                    κατεστραμμένου κυτταρικού DNA του δέρματος
                                    από τις υπεριώδεις ακτινοβολίες, καθώς και
                                    στην ενίσχυση της ανθεκτικότητας του
                                    δέρματος στον ήλιο*. Η αποτελεσματικότητά
                                    του έχει αποδειχθεί μετά από πολλά χρόνια
                                    εκτεταμένων ερευνών που έχουν διεξαχθεί από
                                    τη μητρική εταιρεία του PIZ BUIN®, Johnson
                                    &amp; Johnson, με αρκετά διπλώματα
                                    ευρεσιτεχνίας, τα οποία καλύπτουν την
                                    εφαρμογή του στον τομέα της περιποίησης του
                                    δέρματος και της κοσμετολογίας.
                                  </p>
                                  <p className="footNote">
                                    Πηγή: Derm Conversation Feb 2004 *
                                    Δοκιμασμένο in vitro
                                  </p>
                                  <p>Εικ. 1</p>
                                  <p>
                                    Το καθαρό εκχύλισμα χρυσάνθεμου (Feverfew
                                    PFE™) συμβάλλει στην προστασία κατά των
                                    αλλοιώσεων στα κύτταρα, στη μείωση του
                                    ερυθήματος που προκαλείται από την
                                    ακτινοβολία UVB, καθώς και στην ανάπλαση του
                                    κατεστραμμένου κυτταρικού DNA του δέρματος
                                    από τις υπεριώδεις ακτινοβολίες.
                                  </p>
                                  <p>
                                    Έως και 60% βελτίωση στο ερύθημα και την
                                    ερυθρότητα του δέρματος που προκαλείται από
                                    την ακτινοβολία UVB.
                                  </p>
                                  <p>
                                    Πηγή: Kenvue publication in
                                    Arch Dermatol Res Feb 2008; 300 (2) 69-80,
                                    τυχαιοποιημένη, ελεγχόμενη από εικονικό
                                    φάρμακο, διπλά τυφλή μελέτη σε 12 εθελοντές.
                                  </p>
                                  <p>Εικ. 2</p>
                                  <p>
                                    Το καθαρό εκχύλισμα χρυσάνθεμου (Feverfew
                                    PFE™) συμβάλλει στην προστασία κατά του
                                    ηλιακού εγκαύματος διεγείροντας τις φυσικές
                                    διαδικασίες που βοηθούν στη διατήρηση της
                                    κυτταρικής συνοχής* του δέρματος,
                                    ενισχύοντας παράλληλα την ανθεκτικότητα του
                                    δέρματος στον ήλιο.
                                  </p>
                                  <p>
                                    Πηγή: Kenvue R&amp;D,
                                    Μέθοδος: εφαρμογή ακτινοβολιών UV σε
                                    ανθρώπινο δερματικό μόσχευμα και αξιολόγηση
                                    της αλλοίωσης των κυττάρων από το ηλιακό
                                    έγκαυμα.
                                  </p>
                                  <p className="footNote">
                                    *Δοκιμασμένο in vitro
                                  </p>
                                  <p>
                                    To Feverfew PFE περιέχεται στα προϊόντα PIZ
                                    BUIN® In Sun Moisturising Sun Lotion και PIZ
                                    BUIN® In Sun Ultra Light Sun Spray.*
                                  </p>
                                  <p className="footNote">
                                    *Με εξαίρεση το PIZ BUIN® In Sun Ultra Light
                                    Sun Spray SPF 10
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/39ef095da165482afdc673791b8d83e8_f42.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Γλυκερίνη
                                <div className="textBlock bottom">
                                  <p>
                                    Η γλυκερίνη προσελκύει το νερό και το
                                    συγκρατεί στο δέρμα, το οποίο βοηθά στη
                                    διατήρηση ενός καλού επιπέδου ενυδάτωσης.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected first"
                      href="/gr/our-products/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/hydro-infusion/" target="_self">
                      Hydro Infusion
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/sensitive/" target="_self">
                      Sensitive
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/aftersun/" target="_self">
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/gallery/" target="_self">
                      Gallery
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Διαβάστε περισσότερα');\n} else {\nbox.slideDown();\n$(this).text('Διαβάστε λιγότερα');\n}\n});\n});\n"
          }}
        />
       <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
